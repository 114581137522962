import "./index.scss"
import { __ } from '@wordpress/i18n';
import { useState, useEffect } from "react";
import { useSelect } from "@wordpress/data"

wp.blocks.registerBlockType("tpfplugin/shortcode-block", {
    title: "Shortcode TPF",
    description: "Component source development by TPF GROUP",
    icon: "welcome-learn-more",
    category: "common",
    attributes: {
        shortcodeId: { type: "string" },
    },
    edit: EditComponent,
    save: function () {
        return null
    }
})

function EditComponent(props) {

    const { shortcodeId } = props.attributes;
    let editButton = '';

    const tShortcodes = useSelect(select => {
        return select("core").getEntityRecords("postType", "tpf-shortcode", { per_page: -1 })
    })

    const handleOnchange = (e) => {
        const { shortcodeId } = props.attributes;
        props.setAttributes({ shortcodeId: e.target.value });
    }

    if (tShortcodes == undefined) return <p>Loading...</p>
    
    if(shortcodeId){
        editButton = (
            <a href={wp_settings.adminUrl+'post.php?post='+shortcodeId+'&action=edit'} target="_blank" class="button shortcodeTpf__button">{__('Edit Shortcode', 'TPF')}</a>
        );
    }
    
    return (
        <div className="shortcodeTpf__wrapper">
            <div className="shortcodeTpf__container">
                <select onChange={handleOnchange} class="shortcodeTpf__select">
                    <option value="">Select a shortcode</option>
                    {tShortcodes.map(prof => {
                        return (
                            <option value={prof.id} selected={shortcodeId == prof.id}>
                                {prof.title.rendered}
                            </option>
                        )
                    })}
                </select>
                {
                    editButton
                }
        </div>
        </div >
    )
}